import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MainContainer from '../../../components/dumb/MainContainer';

import phone_3 from "../assets/phone_3.png"


const TeamRegistration = () => {
    return (
        <Box bgcolor="primary.main" height={{xs:"auto", lg:500}} as="section" py={4}>
            <MainContainer sx={{height:"100%"}}>
                <Box  height={"100%"}  width="100%" display="flex"flexDirection={{xs:"column", lg:"row"}} gap={{xs:4,lg:0}}>
                    <Box  width={{xs:"100%", lg:"50%"}} sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:{xs:"center",lg:"flex-start"}}} gap={2}>
                        <Typography color="primary.light" variant="h2" textAlign={{xs:"center", lg:"left"}}>Team Registration</Typography>
                        <Typography color="white" variant="h6" textAlign={{xs:"center", lg:"left"}}>Invite your friends and colleague to run and accomplish the mileage target together</Typography>
                        <Box display="flex" gap={2} flexDirection={{xs:"column", sm:"row"}}>
                            <Box bgcolor="primary.dark" color="white" width={{xs:110, lg:150}} py={1} borderRadius={150} textAlign="center">Easy </Box>
                            <Box bgcolor="primary.dark" color="white" width={{xs:110, lg:150}} py={1} borderRadius={150} textAlign="center">Faster </Box>
                            <Box bgcolor="primary.dark" color="white" width={{xs:110, lg:150}} py={1} borderRadius={150} textAlign="center">User Friendly </Box>
                        </Box>
                    </Box>
                    <Box width={{xs:"100%", lg:"50%"}} sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <Box as="img" src={phone_3} height={{xs:300, lg:600}} />
                    </Box>
                </Box>
            </MainContainer>
        </Box>
    )
}

export default TeamRegistration
