import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MainContainer from '../../../components/dumb/MainContainer';

import phone_2 from "../assets/phone_2.webp"

const Event = () => {
    return (
        <Box bgcolor="primary.main" height={600} overflow="hidden" as="section" >
            <MainContainer>
                <Box  height={600}>
                    <Grid container >
                        <Grid item lg={6}>
                            <Box bgcolor="" height={600} display="flex" flexDirection="column" justifyContent="center">
                                <Typography color="primary.light" variant="h2">Event</Typography>
                                <Typography color="white" variant="h4">Discover new events from our mobile app and start running</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </MainContainer>
            <Box as="img" src={phone_2} display={{xs:"none", lg:"block"}} position="relative" top={-1050} left={"40%"} sx={{transform:"translateX(10%)"}}/>
        </Box>
    )
}

export default Event
