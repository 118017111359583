import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import MainContainer from '../../../components/dumb/MainContainer';

import app_store_badge from "../assets/app-store-badge.png"
import playstore_store_badge from "../assets/google-play-badge.png"





const Experience = () => {
    return (
        <Box height={500} display="flex" justifyContent="center" alignItems="center" as="section">
            <MainContainer  bg="white">
                <Typography color="primary.main" variant="h2" textAlign="center" mb={2}>
                    Start your <Typography variant="span" fontWeight="bold">new experience run</Typography> now
                </Typography>
                <Box width="100%" display="flex" flexDirection={{xs:"row"}} gap={2} justifyContent="center" alignItems="center">
                    <Link href="https://www.google.com" target="_blank">
                            <Box as="img" src={app_store_badge} width={{xs:135, lg:150}} borderRadius={2}/>
                    </Link>
                    <Link href="https://www.google.com" target="_blank">
                        <Box as="img" src={playstore_store_badge} width={{xs:135, lg:150}} borderRadius={2}/>
                    </Link>
                </Box>
            </MainContainer>
        </Box>
    )
}

export default Experience
