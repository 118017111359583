import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Footer from "../../../components/dumb/Footer"
import MainContainer from '../../../components/dumb/MainContainer';
import Header from '../../../components/smart/Header';

const Section = ({children, title}) => 
<Box mb={4}>
    <Typography variant="h5" mb={1} color="primary.main" fontWeight={600}>{title}</Typography>
    <Typography variant="body1" color="secondary.main">
        {children}
    </Typography>
</Box>


const PrivacyPolicy = () => {
    return (
        <Box>
            <Header/>
            <MainContainer sx={{marginTop:8}}>
                <Section title="PUBLIC PRIVACY STATEMENT">
                    Ultra Sport Technology Sdn. Bhd. may collect Personal Information from Members for the purpose of facilitating the Ultra Sport Technology Platform and purpose. Please see our Privacy Policy for more information.
                </Section>

                <Section title="PRIVACY POLICY">
                    1. The privacy of your Personal Information is important to us. All Members’ Personal Information will be handled in accordance with our Privacy Policy.
                    <br/>
                    2. We are required to collect your Personal Information in order to process your Account and to communicate with you about your Account. If we cannot collect from you the minimum amount of Personal Information required, we will not be able to process your membership, and your registration may not be accepted.
                    <br/>
                    3. Where you provide any Personal Information to us about any other person in connection with your membership, you confirm that you have the consent of such other person to provide their Personal Information to us and they consent to the handling by us of their Personal Information on the basis of our Privacy Policy. 
                </Section>

                <Section title="WHEN DO WE COLLECT INFORMATION ABOUT YOU?">
                    4. At times, we are required to collect personal information in the normal course of providing services to our customers.                   
                    <br/>
                    5. Also, we make use of “cookies” on our platforms. A cookie is a small software message sent to your web browser by our web server. Your browser stores the message in a file and the message is then sent back to our servers each time your browser requests a page from our servers.
                    <br/>
                    6. We use cookies to gain statistics on which areas of our sites attract traffic. We also use cookies to improve your experience of our websites. For example, cookies allow us to recognise whether or not you are returning to our websites. In addition, we make use of third parties who use cookies to serve ads based on past visits to our website. This may allow us to alert you to products or offers which we think you might be interested in viewing.
                    <br/>
                    7. Most Internet browsers are set up to accept cookies. If you do not wish to receive cookies, you will need to adjust the settings of your browser to refuse all cookies or to notify you each time a cookie is sent to your computer.
                    <br/>
                    8. When you click on links and banners on our sites that take you to third-party websites, you will be subject to that third-party’s privacy policies. While we support the protection of privacy on the internet, we cannot be held responsible for the actions of any third-party websites.
                </Section>

                <Section title="WHAT INFORMATION DO WE COLLECT?">
                    9. We may collect the following information from you:
                    <br/>
                    •	Your name and contact details, such as your physical address, email address and phone number;
                    <br/>
                    •	Your gender, birthdate and occupation;
                    <br/>
                    •	Your company name (if applicable);
                    <br/>
                    •	The name of the domain from which you access the internet;
                    <br/>
                    •	The date and time you access our sites;
                    <br/>
                    •	Your running statistics and data;
                    <br/>
                    •	The internet address of the website from which you linked directly to our sites; and
                    <br/>
                    •	Other information you may supply to us.
                </Section>

                <Section title="WHY DO WE COLLECT THIS INFORMATION?">
                    10. We use the information to customise our services and plan product development so that we can provide you with relevant consumer information and notify you of products and special offers that may be of interest to you across Ultra Sport Technology and any of our Partners.
                    <br/>
                    11. We use your running statistics and data to transform them into Activity Points that can be redeemed on the Ultra Sport Technology Platform.
                </Section>

                <Section title="DO WE SHARE PERSONAL INFORMATION WITH THIRD PARTIES?">
                    12. We may disclose information we hold to our directors, officers, employees and other associated companies for business purposes and marketing or remarketing purposes through our websites or other social media channels. Some of our associated companies with whom we share personal information may be located overseas.
                    <br/>
                    13. We may also disclose information to third party service providers or contractors. The countries this information may be disclosed to will vary from time to time, but may include Malaysia, Brunei and Singapore.
                    <br/>
                    14. Sometimes we use third party platforms and services to process sales, store and analyse data and information, provide web support, send marketing messages, deliver products or otherwise deliver information. These services are hosted and managed by organisations other than ourselves, and some of these services are hosted overseas. We use products and services maintained in Malaysia, Brunei and Singapore.
                    <br/>
                    15. Your personal information may be stored in a secured and encrypted form overseas (e.g. in data storage and cloud computing facilities operated by us (or by third parties on our behalf)).
                    <br/>
                    16. Where applicable information is shared with these third parties, they are obliged to observe the confidential nature of such information and are prohibited from using any or all of this information other than for the purpose for which it was provided.
                    <br/>
                    17. We will disclose information to a law enforcement agency if we are requested to do so by that agency in relation to suspected unlawful activity.
                    <br/>
                    18. By redeeming Offers that involve Ultra Sport Technology ’s Partners, members agree that Ultra Sport Technology Sdn. Bhd may provide the Member’s Personal Information to the relevant third party in order to fulfill the request and related purposes.
                </Section>

                <Section title="HOW TO DELETE YOUR FACEBOOK USER DATA?">
                    19. Log into your Facebook account.
                    <br/>
                    20. Go to your Facebook profile's Apps and Websites settings tab: https://www.facebook.com/settings?tab=applications
                    <br/>
                    21. Remove Lari La.
                    <br/>
                    22. Click View Removed Apps and Websites then click on Lari La.
                    <br/>
                    23. Click Send Request to request Lari La for deleting your user data.
                </Section>
            </MainContainer>
            <Footer/>
        </Box>
    )
}

export default PrivacyPolicy
