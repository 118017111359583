import React from 'react'
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import MainContainer from '../dumb/MainContainer';
import logo_larila from "../../assets/logo/logo-larila.svg"

const Header = () => {
    return (
        <Box bgcolor="white" as="section" py={2} position="fixed" top={0} zIndex={2} width="100%">
            <MainContainer sx={{height:"100%"}}>
                <Box textAlign={{xs:"center", lg:"left"}}>
                <Link href="#banner">
                    <Box as="img" src={logo_larila} width={{xs:100, lg:150}}/>
                </Link>
                </Box>
            </MainContainer>
        </Box>
    )
}

export default Header
