import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    palette: {
        primary: {
            light : "#FF60BB",
            main: "#A22B63",
        },
        secondary: {
            main: "#555555",
        },
}})

theme = responsiveFontSizes(theme);

export default theme

