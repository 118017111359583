import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import MainContainer from '../../../components/dumb/MainContainer';

import banner_larila from "../assets/banner_larila.jpg"
import logo_larila from "../../../assets/logo/logo-larila.svg"
import phone_1 from "../assets/phone_1.png"
import app_store_badge from "../assets/app-store-badge.png"
import playstore_store_badge from "../assets/google-play-badge.png"


const Banner = () => {
    return (
        <Box position="relative" id="banner"  as="section" sx={{backgroundImage:`url(${banner_larila})`, height:{xs:"100vh", lg:800}, backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPositionY:{xs:0, sm:0, md:0, lg:-50},backgroundPositionX:{xs:-500,sm:-400, md:-250, lg:0}}}>
            <MainContainer sx={{height:"100%", display:"flex", flexDirection:"column", justifyContent:{xs:"center",lg:"space-between"}, alignItems:"left", padding:{xs:0, lg:"100px 0px 48px 0px", }}}>
                <Box textAlign={{xs:"center",lg:"left"}}>
                    {/* <Typography variant='h2' color="primary.main" >LariLa</Typography> */}
                    <Box as="img" src={logo_larila} width={{xs:150, lg:200}}/>
                    <Typography variant='h3' fontWeight="bold"color="secondary.main">Just run, everyone starts small.</Typography>
                </Box>

                <Box width="100%" display="flex" justifyContent={{xs:"center", lg:"right"}} alignItems={{xs:"center", lg:"center"}}flexDirection={{xs:"column", lg:"row"}}  gap={2} mt={2}>
                    <Link href="https://www.google.com" target="_blank">
                            <Box as="img" src={app_store_badge} width={{xs:135, lg:150}} borderRadius={2}/>
                    </Link>
                    <Link href="https://www.google.com" target="_blank">
                        <Box as="img" src={playstore_store_badge} width={{xs:135, lg:150}} borderRadius={2}/>
                    </Link>
                </Box>
                <Box as="img" src={phone_1} position="absolute" bottom={"-30%"} left={{lg:"-10%", xl:"5%"}} sx={{transform:"scale(0.8)"}} display={{xs:"none", lg:"block"}} />
            </MainContainer>
        </Box>
    )
}

export default Banner
