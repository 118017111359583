import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MainContainer from '../../../components/dumb/MainContainer';


const Mission = () => {
    return (
        <Box as="section" height={550} display="flex" justifyContent="center" alignItems="center">
            <MainContainer id="container">
                <Box mb={4}>
                    <Typography variant="h3" color="secondary.main" textAlign="center">"If you can't fly then run, if you can't run then walk, if you can't walk then crawl, 
                    <Typography variant="span" color="primary.main" fontWeight="bold">but whatever you do have to keep moving forward."</Typography></Typography>
                </Box>
                <Typography  variant="h5" textAlign="center" color="secondary.main">Martin Luther King JR</Typography>
            </MainContainer>
        </Box>
    )
}

export default Mission
