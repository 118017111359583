import React from 'react'
import Box from '@mui/material/Box';

import Banner from '../components/Banner';
import Mission from '../components/Mission';
import Event from '../components/Event';
import Track from '../components/Track';
import TeamRegistration from '../components/TeamRegistration'
import Experience from '../components/Experience';

import Footer from '../../../components/dumb/Footer'
import Header from '../../../components/smart/Header';


const Home = () => {
    return (
        <Box as="main">
            <Header/>
            <Banner/>
            <Mission/>
            <Event/>
            <Track/>
            <TeamRegistration/>
            <Experience/>
            <Footer/>
        </Box>
    )
}

export default Home
