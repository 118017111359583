import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MainContainer from '../../../components/dumb/MainContainer';

import track from "../assets/track.svg"


const Track = () => {
    return (
        <Box bgcolor="white" height={{xs:"auto", lg:500}} as="section" py={4}>
            <MainContainer sx={{height:"100%"}}>
                <Box  height={"100%"}  width="100%" display="flex"flexDirection={{xs:"column-reverse", lg:"row"}} gap={{xs:4,lg:0}}>
                    <Box width={{xs:"100%", lg:"50%"}} sx={{display:"flex", justifyContent:{xs:"center", lg:"left"}, alignItems:"center"}}>
                        <Box as="img" src={track} height={{xs:320}}/>
                    </Box>
                    <Box  width={{xs:"100%", lg:"50%"}} sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:{xs:"center",lg:"flex-start"}}}>
                        <Typography color="primary.light" variant="h2">Track</Typography>
                        <Typography color="secondary.main" variant="h4" textAlign={{xs:"center", lg:"left"}}>Keep your momentum by track your progress every single day!</Typography>
                    </Box>
                </Box>
            </MainContainer>
        </Box>
    )
}

export default Track
