import React from 'react'
import { BrowserRouter as Router, Routes, Route
} from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';

import theme from "./theme"

import Home from './pages/home/view/Home';
import PrivacyPolicy from './pages/privacy_policy/view/PrivacyPolicy';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

export default App
