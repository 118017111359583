import React from 'react'
import Container from '@mui/material/Container';


const MainContainer = ({children, ...rest}) => {

    const maxWidth = "lg"

    const containerProp = {
        maxWidth
    }

    return (
        <Container {...containerProp} {...rest}>
            {children}
        </Container>
    )
}

export default MainContainer
